import React from "react";
import Layout from "../components/layout/layout";
import Seo from "../components/seo/seo";
import { useStaticQuery, graphql } from "gatsby";
import Info from "../components/case-study/info";
import HeaderCaseStudy from "../components/headers/header-case-study";
import LogoSlovenia from "../assets/images/case-study/slovenia-control.png"
import BgEaro from "../assets/images/case-study/earo-bg.png"
import ImgFlight from "../assets/images/case-study/flight.png"
import ImgPilot from "../assets/images/case-study/pilot.jpg"
import ImgPlane from "../assets/images/case-study/plane.jpg"
import TextImage from "../components/case-study/text-image";
import Testimonial from "../components/case-study/testimonial";
import { useTranslation } from "react-i18next";
// Header Content
const hTitle = "eARO 2.0";



const Earo = () => {
  const { t } = useTranslation()

  const hTitle = `${t('caseStudy.eAro.title')}`;

  const infoContent = {
    industry: `${t('caseStudy.eAro.info.industry')}`,
    wentLive: `${t('caseStudy.eAro.info.wentLive')}`,
    stage: `${t('caseStudy.eAro.info.stage')}`,
    services: [
      {
        service: `${t('caseStudy.eAro.info.services.first')}`
      }
    ],
  };

  const summaryContent = {
    title: `${t('caseStudy.eAro.summary.title')}`,
    shortText: `${t('caseStudy.about')}`,
    text: `${t('caseStudy.eAro.summary.text')}`,
    image: ImgFlight,
  };
  const aboutProjectContent = {
    title: `${t('caseStudy.eAro.aboutProject.title')}`,
    text: `${t('caseStudy.eAro.aboutProject.firstText')}`,
    secondText: `${t('caseStudy.eAro.aboutProject.secondText')}`,
  };

  const pilotImgContent = {
    image: ImgPilot,
    centered: true,
    soloImage: true
  };

  const aboutCompanyContent = {
    title: "Slovenia Control",
    shortText: `${t('caseStudy.about')}`,
    text: `${t('caseStudy.eAro.aboutCompany.firstText')}`,
    secondText: `${t('caseStudy.eAro.aboutCompany.secondText')}`,
  };

  const outcomeContent = {
    title: `${t('caseStudy.eAro.outcome.title')}`,
    text: `${t('caseStudy.eAro.outcome.text')}`,
    image: ImgPlane,
    centered: true
  };
  const testimonialContent = {
    fullName: "Aleš Omahne,",
    position: `${t('caseStudy.eAro.testimonial.position')}`,
    text: `${t('caseStudy.eAro.testimonial.text')}`
  };


  return (
    <Layout>
      <Seo title={hTitle} />
      <HeaderCaseStudy hTitle={hTitle} bgImage={BgEaro} logo={LogoSlovenia} />
      <Info infoContent={infoContent} />
      <TextImage textImageContent={summaryContent} />
      <TextImage textImageContent={aboutProjectContent} />
      <TextImage textImageContent={pilotImgContent} />
      <TextImage textImageContent={aboutCompanyContent} />
      <TextImage textImageContent={outcomeContent} />
      <Testimonial testimonialContent={testimonialContent} />
    </Layout>
  );
};

export default Earo;

export const query = graphql`
query($language: String!) {
  locales: allLocale(filter: {language: {eq: $language}}) {
    edges {
      node {
        ns
        data
        language
      }
    }
  }
}
`